<template>
  <a-form :model="formState">
    <a-form-item label="二次上传覆盖">
      <a-radio-group v-model:value="formData.is_cover_code">
        <a-radio value="0">是</a-radio>
        <a-radio value="1">否</a-radio>
      </a-radio-group>
    </a-form-item>
    <a-form-item label="生产计划单入库">
      <a-radio-group v-model:value="formData.produce_plan_warehousing">
        <a-radio :value="1">是</a-radio>
        <a-radio :value="0">否</a-radio>
      </a-radio-group>
    </a-form-item>
        <a-form-item label="实体垛标出库">
      <a-radio-group v-model:value="formData.entity_virtual_out">
        <a-radio :value="1">是</a-radio>
        <a-radio :value="0">否</a-radio>
      </a-radio-group>
    </a-form-item>
    <a-form-item>
      <a-button @click="save" type="primary">保存</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import { ref } from "@vue/reactivity";
import {
  getBash,
  bashUpdate,
  getBashpro,
  bashUpdatepro,
} from "@/api/codeManage";
import { inject, onMounted } from "@vue/runtime-core";
import { $iscode } from "../../../utils/app";
export default {
  props: {
    brand_id: String,
  },
  setup(props) {
    const formData = ref({
      is_cover_code: 0,
      produce_plan_warehousing: 0,
      entity_virtual_out:0,
    });
    const router = inject("$router");
    const save = async () => {
      let result = await bashUpdate(props.brand_id, formData.value)
        .then((res) => res.data)
        .catch((error) => error);
      let res = await bashUpdatepro(props.brand_id, formData.value)
        .then((res) => res.data)
        .catch((error) => error);
      if ($iscode(res, true) && $iscode(result, true)) {
        router.go(-1);
      }
    };
    const initData = async () => {
      let result = await getBash(props.brand_id)
        .then((res) => res.data)
        .catch((error) => error);
      formData.value.is_cover_code = result.data.is_cover_code;
      let res = await getBashpro(props.brand_id)
        .then((res) => res.data)
        .catch((error) => error);
      formData.value.produce_plan_warehousing =
        res.data.produce_plan_warehousing;
         formData.value.entity_virtual_out =
        res.data.entity_virtual_out;
    };
    onMounted(() => {
      initData();
    });
    return {
      formData,
      save,
    };
  },
};
</script>